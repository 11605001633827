import { memo, useState } from "react";

import useTranslate from "../../../../../apps/web/app/hooks/useTranslate";
import { LinkWrapper } from "../../../../../apps/web/app/components/linkWrapper";
import { generateRandomNumber } from "../../../../../apps/web/app/lib/functions";

export const ProductCard = memo(
  ({
    product,
    className,
    index,
  }: {
    product: any;
    className?: string;
    index: number;
  }) => {
    const { translate } = useTranslate();
    const [imageIndex, setImageIndex] = useState<number>(0);

    return (
      <div key={index} className={className}>
        <LinkWrapper href={`products/${product.handle}`}>
          <img
            onMouseOver={() =>
              setImageIndex(
                generateRandomNumber(product?.media?.nodes?.length - 1 || 0)
              )
            }
            onMouseOut={() => setImageIndex(0)}
            src={
              product.media.nodes[
                product?.tags?.includes("mx-graphics") ? imageIndex : 0
              ].image.url
            }
            alt="img"
          />
          <div className="mt-5 flex items-center justify-between">
            <span className="max-w-[150px] font-semibold uppercase leading-none lg:max-w-[250px]">
              {product.title}
            </span>
            <div className="text-right font-bold">
              <div>
                {product.variants.nodes.length > 1 ? (
                  <span>
                    {`${translate("from")} ${
                      product.variants?.nodes?.[0].price.currencyCode
                    } ${product.variants?.nodes?.[0].price.amount}`}
                  </span>
                ) : (
                  <span>
                    {`${product.variants?.nodes?.[0].price.currencyCode} ${product.variants?.nodes?.[0].price.amount}`}
                  </span>
                )}
              </div>
              <button className="ml-4 hidden border border-black px-2 py-1.5 text-sm lg:px-3">
                {translate("detailsButton")}
              </button>
            </div>
          </div>
        </LinkWrapper>
      </div>
    );
  }
);
